/* Custom Header/Banner */
.top-header {
    width: 100%;
    border-radius: 20px;
    max-width: auto;
}

.category-container {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 10px;
    justify-items: center;
    align-items: start;
}

.category-item {
    display: grid;
    color: rgb(0, 0, 0);
    border-radius: 20px;
    font-size: 20px;
    max-width: 200px;
}

.img{
    object-fit: cover;
    width: 100%;
    height: 100%;
    border-radius: 20px;
}

.category-button{
    align-content: center;
    padding: 0px;
     border-radius: 20px;
    font-size: 20px;
    min-width: 200px;
    max-width: 200px;
}

@media (max-width: 640px) {
    .category-container {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 20px;
    }

    .category-item {
        min-height: 100px;
        max-width: 200px;
    }   
}